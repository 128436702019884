<!--
 * @Descripttion: 用户管理
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-09-29 08:42:20
-->
<template>
  <el-main>
    <h1 class="base-title">管理-操作员</h1>
    <div class="search mt">
      <el-input  placeholder="请输入用户名"  v-model="searchVal" style="width: 300px; display: inline-block"></el-input>
      <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
      <el-button type="primary" icon="el-icon-plus" @click="showAdd = true">新增用户</el-button>

    </div>

    <el-table :data="list" border stripe class="mt">
      <el-table-column label="序号" type="index"  width="80px" align="center" />
      <!-- <el-table-column label="头像" width="100px" align="center">
        <template slot-scope="{row}">
          <img :src="row.portrait" style="width:44px; height:44px">
        </template>
      </el-table-column> -->
      <el-table-column label="账号" min-width="150px" prop="username"/>
      <el-table-column label="联系方式" min-width="150px" align="center" prop="phone"></el-table-column>
      <el-table-column label="是否操作员" min-width="120px" align="center">
        <template slot-scope="{row}">
          <el-tag type="success" v-if="row.roleCode == '20'">是</el-tag>
          <el-tag type="danger"  v-else>否</el-tag>
        </template>
      </el-table-column>    
      <el-table-column label="创建时间" min-width="200px" align="center" prop="createTime" />

      <el-table-column label="操作" width="200px" align="center">
        <template slot-scope="{row}">
          <el-button type="success" size="mini"  @click="handleUpdate(row,'20')" v-if="row.roleCode != '20'">设为操作员</el-button>
          <el-button type="danger" size="mini"  @click="doUpdateOperator(row.mapId,'00')" v-else-if="row.roleCode == '20'">取消操作员</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt"
      v-if="total > 0"
      @size-change="onPageSizeChange"
      @current-change="onCurrentPageChange"
      :current-page.sync="params.pageNo"
      :page-sizes="[10, 20, 40, 80, 100]"
      :page-size="params.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total" background>
    </el-pagination>

   <!-- 添加新用户 DILOG -->
    <el-dialog
      title="新增用户"
      :visible.sync="showAdd"
      width="30%"
      center
      @closed="onclosed"
    >
      <el-form :model="userForm" ref="userForm" :rules="rules" label-width="80px">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="userForm.username" placeholder="请输入手机号码"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="userForm.password" placeholder="密码由8位数字、字母和特殊符号组成"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmpassword">
          <el-input v-model="userForm.confirmpassword" placeholder="请再次输入密码"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="linkMan">
          <el-input v-model="userForm.linkMan" placeholder="请输入联系人名称"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click=" showAdd = false">取消</el-button>
        <el-button type="primary" @click="handleAdd">添加</el-button>
      </span>
    </el-dialog>

  </el-main>
</template>

<script>
import {httpGet, httpPut, httpPost} from '@/utils/http'
import global from '@/common/global'
import { checkUsername, checkPassword, checkConfirmPassword } from '@/utils/validator'
export default {
  name: 'User',
  data() {
    return {
      searchVal: '',
      inputStyle: {width: '300px'},
      list: [],
      total: 0,
      maxOperator: 0,
      params: {
        pageSize: 10,
        pageNo: 1,
        ewStoreId: '',
        username: ''
      },
      url: {
        // get: '/ewbid/store/tbEwStoreExternal/getOperatorList',
        get: '/ewbid/store/tbEwStoreExternal/getWxStoreUserList',
        getOperatorNumber: '/ewbid/store/tbEwStoreExternal/getOperatorNumber',
        update: '/ewbid/store/tbEwStoreExternal/updateOperator'
      },
      showAdd: false,
      userForm: {
        username: '',
        password: '',
        confirmpassword: '',
        linkMan: '',
      },
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
          {validator: checkUsername, trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {validator: checkPassword, trigger: 'blur'}
        ],

        confirmpassword: [
          { required: true, message: '请再次输入密码', trigger: 'blur'},
          { validator: this.checkConfirmPassword, trigger: 'blur'}
        ],
        linkMan:[
          { required: true, message: '请输入联系人姓名', trigger: 'blur'}
        ]
      }
    }
  },

  created() {
    this.params.ewStoreId = this.$ls.get("storeInfo").id
    this.getUserList()
  },

  methods: {

    /**
     * @Description: 根据昵称搜索（重置当前页为1）
     * @Author: Niklaus
     * @Date: 2021-09-29 14:46:07
     */
    handleSearch() {
      this.params.pageNo = 1
      this.getUserList()
    },

    /**
     * @Description: 获取当前机构的操作员列表
     * @Author: Niklaus
     * @Date: 2021-09-29 11:31:12
     */
    getUserList() {
      this.searchVal = this.searchVal.trim();
      this.params.username = this.searchVal
      httpGet(global.gateway + this.url.get, this.params).then( ({data}) =>{
        if(data.success) {
          this.total = data.result.total
          this.list = data.result.records
          this.maxOperator = data.result.size
        }else {
          this.$message.error('数据查询失败，请稍后重试')
        }
      })
    },

    /**
     * @Description: 添加新用户
     * @Author: Niklaus
     * @Date: 2022-08-19 14:20:26
     */
    handleAdd() {
      this.$refs['userForm'].validate( valid => {
        if(valid) {
          let params = {
            ...this.userForm,
            orgId: this.$ls.get("storeInfo").orgId,
            ewStoreId: this.$ls.get("storeInfo").id
          }
          httpPost(`${global.gateway}/sys/user/addBidUser`, params).then(res => {
            if(res.data.success) {
              this.showAdd = false
              this.handleSearch()
              this.$message.success('添加成功')
            }else
              this.$message.error('添加失败')
          }).catch(_=> this.$message.error('添加失败'))
        }
      })
    },

    /**
     * @Description: 查询操作员数量是否已达到最大值
     * @Author: Niklaus
     * @Date: 2021-09-29 14:48:38
     */
    handleUpdate({mapId}, roleCode){
      httpGet(global.gateway + this.url.getOperatorNumber,this.params).then( ({data}) => {
        if(data.success) {
          if(data.result >= this.maxOperator) {
            this.$notify({
              title: '提示',
              message: `最多可设置${this.maxOperator}个操作员，请先取消原操作员后再执行此操作`,
              type: 'warning'
            })
            return Promise.reject('')
          }else
            return ''
        }else {
          this.$message.error('查询操作员数据出错')
        }
      }).then(_=>{
        /** 执行更新操作 */
        this.doUpdateOperator(mapId, roleCode)
      }).catch(error => {
        if(error)
          this.$message.error('查询操作员数据错误')
      })
    },

    /**
     * @Description: 执行更新操作
     * @Author: Niklaus
     * @Date: 2021-09-29 17:14:52
     * @param {*} id
     * @param {*} operatorFlag
     */
    doUpdateOperator(id, roleCode) {
      let operatorFlag = '';
      if(roleCode=='00'){
        operatorFlag ='0'
      }else if(roleCode=='20'){
          operatorFlag ='1'
      }else{
        operatorFlag ='0'
      }
      httpPut(global.gateway + this.url.update, {id, roleCode,operatorFlag}).then( ({data}) => {
        if(data.success) {
          this.$notify({
            title: '成功',
            message: '操作成功',
            type: 'success'
          })
          this.params.pageNo = 1
          this.getUserList()
        } else
          this.$message('更新操作员状态出错:' + data.mesasge)
      })
    },


    /**
     * @Description: pageSize 发生改变的触发事件
     * @Author: Niklaus
     * @Date: 2021-09-29 14:22:35
     * @param {*} pageSize
     */
    onPageSizeChange(pageSize) {
      const maxPageNo = Math.ceil(this.total / pageSize)
      /** 判断此次改变是否出触发 当前的页数变化，这里只处理 页数不变化，否则会查询两遍 */
      this.params.pageSize = pageSize
      if(maxPageNo >= this.param.pageNo) {
        this.getUserList()
      }
    },

    /**
     * @Description: 上下页 / 点击页数 / 跳页 触发事件
     * @Author: Niklaus
     * @Date: 2021-09-29 14:46:54
     * @param {*} pageNo
     */
    onCurrentPageChange(pageNo) {
      this.getUserList()
    },

    checkConfirmPassword(rule, value, callback) {
      checkConfirmPassword({...rule, password: this.userForm.password}, value, callback)
    },

    onclosed() {
      Object.keys(this.userForm).forEach( key => this.userForm[key] = '')
    }
  }
}
</script>

<style>

</style>